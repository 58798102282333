import { DeferredLink } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";

export function deferredDeepLink__server__get(p: { deferredLinkId: string }): Promise<DeferredLink | null> {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  return h.DeferredLink.getDoc(p.deferredLinkId);
  // SERVER_ONLY_TOGGLE
}

deferredDeepLink__server__get.auth = () => {};

// i18n certified - complete
