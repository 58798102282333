import { getUniversalHelpers } from "../../helpers";
import { extractIpAddress, getDeviceType, getDeviceLookupString } from "../../compute/deferredLink.compute";
import * as express from "express";
import { DeferredLink } from "@ollie-sports/models";

//This endpoint is not idempotent. Trigger it wisely...
export async function deferredDeepLink__server__lookup(
  this: { request: express.Request },
  p: { userAgent: string; deviceId: string }
) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h, olliePipe } = getUniversalHelpers();
  const { userAgent } = p;

  const ip = extractIpAddress(this.request);

  if (!ip) {
    throw new Error("No ip address. Unable to perform desired deferredLink action");
  }

  //Base case: find deferred links with the same IP address
  const links = await h.DeferredLink.query({
    where: [{ ip: ["==", ip] }, { createdAtMS: [">=", Date.now() - 1000 * 60 * 10] }], //Link is only good for 10 minutes
    orderBy: [{ pathObj: { createdAtMS: true }, dir: "desc" }]
  }).then(a => a.docs);

  //If possible, filter base links using additional device fingerprint data
  const lookupString = getDeviceLookupString({ userAgent });

  const deviceFilteredLinks = links.filter(
    a =>
      lookupString ===
      getDeviceLookupString({
        userAgent: a.userAgent
      })
  );

  const deviceType = getDeviceType(userAgent || "") || "UNKNOWN";

  // Note: We have consciously decided to fallback on an IP only match (links[0]) if we can't figure things out from the useragent.
  // The thinking is our user agent parsing code needs to be refined some more hence it misses matches, and the security concerns for a user accessing the wrong url is low
  const link = deviceFilteredLinks[0] || links[0] || null; //False positives aren't terrible, so take what we can get

  const { url, urlSearchParams } = link ? link : ({} as Record<string, undefined>);

  olliePipe.emitEvent({
    type: "metric-deferred-link-lookup-results",
    payload: {
      success: !!link,
      url,
      urlSearchParams,
      deviceLookupString: lookupString,
      os: deviceType,
      linkId: link?.id ?? null,
      baseLinksFound: links.length,
      uaFilteredLinksFound: deviceFilteredLinks.length,
      mismatch:
        links.length !== deviceFilteredLinks.length
          ? { currUserAgent: p.userAgent, savedUserAgents: links.map(l => l.userAgent) }
          : null
    }
  });

  return link as DeferredLink | null;
  // SERVER_ONLY_TOGGLE
}

deferredDeepLink__server__lookup.auth = () => {};

// i18n certified - complete
